

    import { defineComponent, PropType } from 'vue'
    import Column from './Grid/Column.vue'
    import Grid from './Grid/Grid.vue'
    import Anchor from './Anchor.vue'
    import Paragraph from './Paragraph.vue'
    import LinkArrow from './Logos/LinkArrow.vue'
    import { Route } from '../enums/Route'
    import { CaseListInterface } from '../Interfaces/CaseListInterface'
    import chunk from 'lodash.chunk'

    export default defineComponent({
        name: 'TitleAndList',
        components: {
            Anchor,
            Paragraph,
            LinkArrow,
            Column,
            Grid
        },
        props: {
            title: { type: String, required: false },
            list: { type: Array as PropType<string[] | CaseListInterface[]>, required: true },
            index: { type: Number, default: 0 },
            careers: { type: Boolean, default: false },
            stacked: { type: Boolean, default: false },
            threeColumns: { type: Boolean, default: false },
            larger: { type: Boolean, default: false }
        },
        setup(props) {

            return {
                Route,
                items: props.threeColumns ? chunk(props.list, Math.ceil(props.list.length / 3)) : chunk(props.list, Math.ceil(props.list.length / 2))
            }

        }
    })

